import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export class PhotoGallery {
  static setup() {
    $(document).ready( function() {
      $('#open-gallery').click( function() {
        openGallery();
      });

      $('.gallery-photo').click( function() {
        openGallery(this);
      });

      function openGallery(clickedImage) {
        var pswpElement = document.querySelectorAll('.pswp')[0];

        var items = $('.gallery-photo').toArray().map(function(photo) {
          var data = $(photo).data();
          data.src = $(photo).attr('src');
          return data;
        });

        var index = 0;

        if(clickedImage) {
          var clickedImageId = $(clickedImage).data('id');
          index = items.findIndex(function(item) {
            return item.id === clickedImageId;
          });
        }

        var options = { index: index,
          loop: false,
          shareButtons: [{ id: 'info', label: 'Photo Info', url: 'https://rad.gallery/photos/info?source={{image_url}}' },
            { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }] };

        // Initializes and opens PhotoSwipe
        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
      }
    });
  }
}
