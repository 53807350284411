import { Photos } from '../src/photos';
require('raty-js/lib/jquery.raty');
import 'raty-js/lib/jquery.raty.css';

import '../src/bootstrap_overrides.scss';

import { PhotoGallery } from '../src/photo-gallery';
import { SelectAll } from '../src/select_all';

require('chart.js/dist/Chart.min.css');
import { WeightChart } from '../src/weight_chart';

$(document).ready(function() {
  Photos.setup();
  PhotoGallery.setup() ;
  SelectAll.setup();
  WeightChart.setup();
});
