import starOffUrl from './images/star-off.png';
import starOnUrl from './images/star-on.png';
import starHalfUrl from './images/star-half.png';

export class Photos {
  static setup() {
    $(document).ready(function() {
      $('.star').each(function(_i, _obj) {
        $(this).raty({
          readOnly: true,
          score: $(this).attr('data-average-rating'),
          path: '',
          starOn: Photos.starOn(),
          starOff: Photos.starOff(),
          starHalf: Photos.starHalf()
        });
      });

      $('.user_star').each(function(_i, _obj) {
        $(this).raty({
          score: $(this).attr('data-rating'),
          starOn: Photos.starOn(),
          starOff: Photos.starOff(),
          starHalf: Photos.starHalf(),
          path: '',
          click: function(score, _evt) {
            $.ajax({
              url: '/rates',
              type: 'POST',
              data: {
                stars: score,
                user_id: $(this).attr('data-user-id'),
                photo_id: $(this).attr('data-photo-id')
              }
            });
          }
        });
      });
    });
  }

  static starOn() {
    return starOnUrl;
  }

  static starOff() {
    return starOffUrl;
  }

  static starHalf() {
    return starHalfUrl;
  }
}
