import { Chart } from 'chart.js/dist/Chart.min';

export class WeightChart {
  static setup() {
    $('.weightChart').each(function() {
      WeightChart.loadChartData(this, $(this).data('chart-data'), $(this).data('labels'));
    });
  }

  static loadChartData (element, chart_data, labels) {
    let data, options;

    options = {
      maintainAspectRatio: false,
      legend: { display: false },
      elements: {
        point: { pointStyle: 'circle', radius: 5, hoverRadius: 7, borderWidth: 3, backgroundColor: '#ffffff' }
      }
    };

    data = { labels: labels, datasets: [{ data: chart_data, borderColor: 'rgba(30, 130, 76, 1)' }] };
    new Chart(element, { type: 'line', data: data, options: options });
    $(element).addClass('initialized');
  }
}
